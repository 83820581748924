<template>
	<div>
		<h1>{{ tittle }}</h1>
		<div class="waterfall-container">
			<div v-for="(col, index) in columns" :key="index" class="column">
				<div v-for="item in col" :key="item.id" class="waterfall-item">
					<a :href="item.link" target="_blank">
						<img :src="item.src" :alt="item.title" />
					</a>
					<p>{{ item.title }}</p>
				</div>
			</div>
		</div>
		<meta name="description"
			content="Fun cat-focused videos for home & neighborhood! HD clips of playful kittens chasing toys, funny antics, and feline adventures. Keep cats entertained indoors. Watch now at videos-for-cats.com">
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		watch
	} from 'vue'

	// 瀑布流数据
	const items = ref(Array.from(
		[{
				title: `The quilt has grown a cat`,
				src: `https://i.ytimg.com/vi/Wq2CQfoQOZc/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLBCOqwVJEEt1CxJ6y_DDCHI8H-vJQ`,
				link: `https://www.youtube.com/shorts/Wq2CQfoQOZc`
			},
			{
				title: `Silly cute kitten`,
				src: `https://i.ytimg.com/vi/aS13OVAoHCI/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLDSC03Yzc12pPqVoNAcXw0sPOtaHg`,
				link: `https://www.youtube.com/shorts/aS13OVAoHCI`
			},
			{
				title: `Cute Kittens`,
				src: `https://i.ytimg.com/vi/4-eiFy0XCIc/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLCr8sLQ-j4tBzzSDN4YvUkUxJ37eA`,
				link: `https://www.youtube.com/shorts/4-eiFy0XCIc`
			},
			{
				title: `The kitten ate all the food`,
				src: `https://i.ytimg.com/vi/i2QKR1YeJcE/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLAX-DEFVqqbjIBkcg9XeupcffgzVA`,
				link: `https://www.youtube.com/shorts/i2QKR1YeJcE`
			},
			{
				title: `Two lazy cats`,
				src: `https://i.ytimg.com/vi/yEVDpUg-12A/oar2.jpg?sqp=-oaymwEoCJQDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLAc-0pLeKa6bLNEr9MnkIaofo8smA`,
				link: `https://www.youtube.com/shorts/yEVDpUg-12A`
			},
			{
				title: `What did you see`,
				src: `https://i.ytimg.com/vi/waZA8ELjSBM/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLD2aBhd52jujPU1WdLHBf_0io_k_A`,
				link: `https://www.youtube.com/shorts/waZA8ELjSBM`
			},
			{
				title: `Eat well, baby`,
				src: `https://i.ytimg.com/vi/_Cu-G5WJ1fs/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLBvEfjBNKvulQGEN5jUUbHkQyvXpA`,
				link: `https://www.youtube.com/shorts/_Cu-G5WJ1fs`
			},
			{
				title: `Do you want to eat snacks`,
				src: `https://i.ytimg.com/vi/wByHvBROj9Y/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLDaLMDAWtWcFxg1ZX1DutYFE6C2yw`,
				link: `https://www.youtube.com/shorts/wByHvBROj9Y`
			},
			{
				title: `I can't catch anything`,
				src: `https://i.ytimg.com/vi/JeSmNDdcZtM/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLAc8Yr94ya83ocQGlnO7TXXipLxMA`,
				link: `https://www.youtube.com/shorts/JeSmNDdcZtM`
			},
			{
				title: `Let's indulge in a crab today`,
				src: `https://i.ytimg.com/vi/1EkzyyknVN0/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLD8QhqjcsF6_s8PoZ-qomyg0GCSKg`,
				link: `https://www.youtube.com/shorts/1EkzyyknVN0`
			},
			{
				title: `I don't know what I'm saying`,
				src: `https://i.ytimg.com/vi/QlidyuuM908/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLCZvPoaNcIZcQ_HyLZHsEPCJ-VKLA`,
				link: `https://www.youtube.com/shorts/QlidyuuM908`
			},
			{
				title: `I'm out of ideas`,
				src: `https://i.ytimg.com/vi/LdByLOJBui8/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLC1R5EwLB880EOdzT-IJUtow4wJ_w`,
				link: `https://www.youtube.com/shorts/LdByLOJBui8`
			},
			{
				title: `The skewers are so delicious`,
				src: `https://i.ytimg.com/vi/qzVBCcxSCds/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLBFKTHbrasKHabDVAMJ7Oe41CM6ZQ`,
				link: `https://www.youtube.com/shorts/qzVBCcxSCds`
			},
			{
				title: `Rock together`,
				src: `https://i.ytimg.com/vi/p0GBtyLGN4w/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLCdizp99xIWTb11_Jobbi7NumleWQ`,
				link: `https://www.youtube.com/shorts/p0GBtyLGN4w`
			},
			{
				title: `Is it delicious?`,
				src: `https://i.ytimg.com/vi/zS5hXM41uZY/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLCDcRGL_3s78Z2NM8Oi4F9HJkod3Q`,
				link: `https://www.youtube.com/shorts/zS5hXM41uZY`
			},
			{
				title: `I'm going to clean up`,
				src: `https://i.ytimg.com/vi/_Gn6jTzz2cw/oar2.jpg?sqp=-oaymwEoCJQDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLCk6kU9Z5n2MbC93-c1jWs0IpwpZQ`,
				link: `https://www.youtube.com/shorts/_Gn6jTzz2cw`
			},
			{
				title: `This is so fun`,
				src: `https://i.ytimg.com/vi/ScJpWc3oHTM/oar2.jpg?sqp=-oaymwEoCJUDENAFSFqQAgHyq4qpAxcIARUAAIhC2AEB4gEKCBgQAhgGOAFAAQ==&rs=AOn4CLAbyU75Bot0A2-WScFmhV_pCxKO8g`,
				link: `https://www.youtube.com/shorts/ScJpWc3oHTM`
			},
		]
	))

	const tittle = 'Cat Videos for Cats to Watch | Funny Feline Videos Collection'
	const columns = ref([]) // 存放每一列的数据
	const columnCount = ref(4) // 默认 3 列

	// 计算列数（响应式）
	const updateColumns = () => {
		const width = window.innerWidth
		columnCount.value = width < 600 ? 1 : width < 900 ? 2 : width < 1200 ? 3 : 4 // 适配不同屏幕

		// 创建列数组
		columns.value = Array.from({
			length: columnCount.value
		}, () => [])

		// 按列插入数据，保证最短列优先
		items.value.forEach((item) => {
			const minColumn = columns.value.reduce((min, col, i) =>
				col.length < columns.value[min].length ? i : min, 0)
			columns.value[minColumn].push(item)
		})
	}

	// 监听窗口大小变化
	onMounted(() => {
    document.title = "Cat Videos for Cats to Watch | Funny Feline Videos Collection | videos-for-cats.com";
    const metaTag = document.querySelector('meta[name="keywords"]');
    if (metaTag) {
      metaTag.setAttribute("content", "cat videos for cats, funny feline videos, indoor cat entertainment, HD cat videos, kittens chasing toys, cat TV stimulation, videos for bored cats, funny cat antics, calming cat videos");
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "keywords";
      newMetaTag.content = "cat videos for cats, funny feline videos, indoor cat entertainment, HD cat videos, kittens chasing toys, cat TV stimulation, videos for bored cats, funny cat antics, calming cat videos";
      document.head.appendChild(newMetaTag);
    }
		updateColumns()
		window.addEventListener('resize', updateColumns)
	})

	watch(items, updateColumns)
</script>

<style scoped>
	h1 {
		font-size: 32px;
		/* 设置字体大小 */
		color: #7c3aed;
		/* 设置字体颜色 */
	}

	.waterfall-container {
		display: flex;
		gap: 16px;
		padding: 16px;
		/* 左边距 60px */
		padding-left: 60px;
		/* 右边距 60px */
		padding-right: 60px;
	}

	.column {
		flex: 1;
		display: flex;
		flex-direction: column;
		/* gap: 16px; */
		row-gap: 40px;
		column-gap: 20px;
	}

	.waterfall-item {
		background: #fff;
		border-radius: 8px;
		overflow: hidden;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	}

	.waterfall-item img {
		width: 100%;
		display: block;
	}

	.waterfall-item p {
		padding: 8px;
		text-align: center;
		font-size: 14px;
		color: #636262;
	}
</style>
