<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
<!--  <VideoList/>-->
  <WaterfallVideos/>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import VideoList from './components/VideoList.vue'
import WaterfallVideos from "./components/WaterfallVideos.vue"

export default {
  name: 'App',
  components: {
    // HelloWorld
	// VideoList
    WaterfallVideos
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
